import './App.css';
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import PolicyPage from './policy/PolicyPage';
import HomePage from './HomePage/HomePage';
import DeletePage from './DeletePage/DeletePage';
import ReactDOM from 'react-dom';
// import homePageHTML from './HomePage/index.html';


function App() {
  return (
    <Router>
      <Routes>
        <Route path="/policy" element={<PolicyPage />} />
        <Route path="/" element={<HomePage />} />
        <Route path="/delete" element={<DeletePage />} />
      </Routes>
    </Router>
  );
}

export default App;
