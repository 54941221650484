import React from "react";
import { Link } from "react-router-dom";
import TwitterLogoDark from "./_nuxt/twitter_logo_dark.5b1cde21.svg";

function HomePage() {
  return (
    <>
  <meta charSet="utf-8" />
  <title>
    Explore our app to find and save Surprise Bags of food - Too Good To Go{" "}
  </title>
  <meta name="viewport" content="width=device-width, initial-scale=1" />
  <link rel="icon" type="image/x-icon" href="../../favicon.ico" />
  <meta
    property="og:title"
    content="Explore our app to find and save Surprise Bags of food"
  />
  <meta property="og:image" content="" />
  <meta
    property="og:description"
    content="The Too Good To Go app enables you to make a difference, by rescuing good food at an even better price."
  />
  <meta property="og:url" content="/en-gb/user" />
  <meta
    name="description"
    content="The Too Good To Go app enables you to make a difference, by rescuing good food at an even better price."
  />
  <meta name="keywords" content="" />
  <meta
    name="google-site-verification"
    content="OkBPKT85F6BwP6srvakZUbUr4yOpuuskjTZSTnS1OeA"
  />
  <link rel="alternate" hrefLang="da" href="jYg3mVfPLUOe.com/da/user" />
  <link rel="alternate" hrefLang="en-gb" href="jYg3mVfPLUOe.com/en-gb/user" />
  <link rel="alternate" hrefLang="no" href="jYg3mVfPLUOe.com/no/user" />
  <link rel="alternate" hrefLang="de" href="jYg3mVfPLUOe.com/de/user" />
  <link rel="alternate" hrefLang="fr" href="jYg3mVfPLUOe.com/fr/user" />
  <link rel="alternate" hrefLang="de-ch" href="jYg3mVfPLUOe.com/de-ch/user" />
  <link rel="alternate" hrefLang="fr-ch" href="jYg3mVfPLUOe.com/fr-ch/user" />
  <link rel="alternate" hrefLang="nl" href="jYg3mVfPLUOe.com/nl/user" />
  <link rel="alternate" hrefLang="nl-be" href="jYg3mVfPLUOe.com/nl-be/user" />
  <link rel="alternate" hrefLang="fr-be" href="jYg3mVfPLUOe.com/fr-be/user" />
  <link rel="alternate" hrefLang="es" href="jYg3mVfPLUOe.com/es/user" />
  <link rel="alternate" hrefLang="de-at" href="jYg3mVfPLUOe.com/de-at/user" />
  <link rel="alternate" hrefLang="it" href="jYg3mVfPLUOe.com/it/user" />
  <link rel="alternate" hrefLang="pl" href="jYg3mVfPLUOe.com/pl/user" />
  <link rel="alternate" hrefLang="pt" href="jYg3mVfPLUOe.com/pt/user" />
  <link rel="alternate" hrefLang="it-ch" href="jYg3mVfPLUOe.com/it-ch/user" />
  <link rel="alternate" hrefLang="sv" href="jYg3mVfPLUOe.com/sv/user" />
  <link rel="alternate" hrefLang="en-us" href="jYg3mVfPLUOe.com/en-us/user" />
  <link rel="alternate" hrefLang="en-ca" href="jYg3mVfPLUOe.com/en-ca/user" />
  <link rel="alternate" hrefLang="fr-ca" href="jYg3mVfPLUOe.com/fr-ca/user" />
  <link rel="alternate" hrefLang="en-ie" href="jYg3mVfPLUOe.com/en-ie/user" />
  <link
    rel="modulepreload"
    as="script"
    crossOrigin=""
    href="/_nuxt/entry.1e274cfc.js"
  />
  <link rel="preload" as="style" href="/_nuxt/entry.f1d14e5f.css" />
  <link
    rel="modulepreload"
    as="script"
    crossOrigin=""
    href="/_nuxt/default.140be409.js"
  />
  <link
    rel="modulepreload"
    as="script"
    crossOrigin=""
    href="/_nuxt/TgtgBanner.5c32b6e2.js"
  />
  <link
    rel="modulepreload"
    as="script"
    crossOrigin=""
    href="/_nuxt/nuxt-link.39403192.js"
  />
  <link
    rel="modulepreload"
    as="script"
    crossOrigin=""
    href="/_nuxt/TgtgAsideHero.vue.6ee3dad3.js"
  />
  <link rel="preload" as="style" href="/_nuxt/TgtgAsideHero.43b76257.css" />
  <link
    rel="preload"
    as="style"
    href="/_nuxt/TgtgDownloadAppQrModal.f717836d.css"
  />
  <link
    rel="preload"
    as="style"
    href="/_nuxt/TgtgLanguageChangePopUp.5cc93a59.css"
  />
  <link
    rel="preload"
    as="style"
    href="/_nuxt/TgtgAccessibleSelect.86e59d48.css"
  />
  <link
    rel="preload"
    as="style"
    href="/_nuxt/TgtgBackgroundHero.32bfaa07.css"
  />
  <link rel="preload" as="style" href="/_nuxt/TgtgCheckBox.2bd46282.css" />
  <link rel="preload" as="style" href="/_nuxt/TgtgContactForm.a03a322c.css" />
  <link rel="preload" as="style" href="/_nuxt/TgtgSelect.7550e839.css" />
  <link rel="preload" as="style" href="/_nuxt/TgtgSocialShare.292d5883.css" />
  <link
    rel="preload"
    as="style"
    href="/_nuxt/TgtgLeadRequestForm.5460632e.css"
  />
  <link rel="preload" as="style" href="/_nuxt/TgtgLister.0b814ffb.css" />
  <link
    rel="modulepreload"
    as="script"
    crossOrigin=""
    href="/_nuxt/TgtgHeroHeading.fdbeda34.js"
  />
  <link rel="preload" as="style" href="/_nuxt/TgtgHeroHeading.798366aa.css" />
  <link
    rel="modulepreload"
    as="script"
    crossOrigin=""
    href="/_nuxt/TgtgPagination.0cd4c39e.js"
  />
  <link rel="preload" as="style" href="/_nuxt/TgtgPagination.7e295d65.css" />
  <link
    rel="modulepreload"
    as="script"
    crossOrigin=""
    href="/_nuxt/TgtgSliderPromo.vue.064eb4c4.js"
  />
  <link rel="preload" as="style" href="/_nuxt/TgtgSliderPromo.f6a2bf32.css" />
  <link
    rel="modulepreload"
    as="script"
    crossOrigin=""
    href="/_nuxt/index.0a57f00e.js"
  />
  <link
    rel="modulepreload"
    as="script"
    crossOrigin=""
    href="/_nuxt/TgtgFAQ.vue.3e6e9cf2.js"
  />
  <link rel="preload" as="style" href="/_nuxt/TgtgFAQ.3acbdacc.css" />
  <link
    rel="modulepreload"
    as="script"
    crossOrigin=""
    href="/_nuxt/_baseToString.76147b05.js"
  />
  <link
    rel="modulepreload"
    as="script"
    crossOrigin=""
    href="/_nuxt/faqs.dfbcdd6c.js"
  />
  <link
    rel="modulepreload"
    as="script"
    crossOrigin=""
    href="/_nuxt/deepMerge.edacf64e.js"
  />
  <link
    rel="modulepreload"
    as="script"
    crossOrigin=""
    href="/_nuxt/testimonials.59c9d077.js"
  />
  <link
    rel="modulepreload"
    as="script"
    crossOrigin=""
    href="/_nuxt/blogs.d2df64de.js"
  />
  <link
    rel="modulepreload"
    as="script"
    crossOrigin=""
    href="/_nuxt/blogsService.6d6e75e7.js"
  />
  <link
    rel="modulepreload"
    as="script"
    crossOrigin=""
    href="/_nuxt/TgtgSlottedHeroDesktopImage.vue.c9925dcb.js"
  />
  <link
    rel="preload"
    as="style"
    href="/_nuxt/TgtgSlottedHeroDesktopImage.3d661a94.css"
  />
  <link
    rel="modulepreload"
    as="script"
    crossOrigin=""
    href="/_nuxt/TgtgTestimonialCard.vue.450f1285.js"
  />
  <link
    rel="preload"
    as="style"
    href="/_nuxt/TgtgTestimonialCard.47e075a1.css"
  />
  <link
    rel="modulepreload"
    as="script"
    crossOrigin=""
    href="/_nuxt/asyncData.493161b8.js"
  />
  <link
    rel="modulepreload"
    as="script"
    crossOrigin=""
    href="/_nuxt/TgtgWysiwyg.vue.0630116a.js"
  />
  <link rel="preload" as="style" href="/_nuxt/TgtgWysiwyg.a5bff456.css" />
  <link
    rel="modulepreload"
    as="script"
    crossOrigin=""
    href="/_nuxt/TgtgHeader.bba7f223.js"
  />
  <link
    rel="modulepreload"
    as="script"
    crossOrigin=""
    href="/_nuxt/TgtgAppMarketLogos.cc489051.js"
  />
  <link
    rel="modulepreload"
    as="script"
    crossOrigin=""
    href="/_nuxt/useDownloadApp.c489ce2f.js"
  />
  <link
    rel="modulepreload"
    as="script"
    crossOrigin=""
    href="/_nuxt/tgtg_logo.b8941e91.js"
  />
  <link
    rel="modulepreload"
    as="script"
    crossOrigin=""
    href="/_nuxt/useFlushLocaleRelativeStores.00819e0d.js"
  />
  <link
    rel="modulepreload"
    as="script"
    crossOrigin=""
    href="/_nuxt/TgtgSelect.cb0d21ae.js"
  />
  <link
    rel="modulepreload"
    as="script"
    crossOrigin=""
    href="/_nuxt/deepEqual.ecfca20c.js"
  />
  <link
    rel="modulepreload"
    as="script"
    crossOrigin=""
    href="/_nuxt/index.502b6165.js"
  />
  <link
    rel="modulepreload"
    as="script"
    crossOrigin=""
    href="/_nuxt/TgtgBackgroundHero.609804f5.js"
  />
  <link
    rel="modulepreload"
    as="script"
    crossOrigin=""
    href="/_nuxt/TgtgStatsPromo.a0135c09.js"
  />
  <link
    rel="modulepreload"
    as="script"
    crossOrigin=""
    href="/_nuxt/TgtgPartnersSection.2b5348e8.js"
  />
  <link
    rel="modulepreload"
    as="script"
    crossOrigin=""
    href="/_nuxt/TgtgSliderPromo.c2b497c2.js"
  />
  <link
    rel="modulepreload"
    as="script"
    crossOrigin=""
    href="/_nuxt/TgtgFAQ.6ebf8759.js"
  />
  <link
    rel="modulepreload"
    as="script"
    crossOrigin=""
    href="/_nuxt/picture-placeholder.d9e3fde8.js"
  />
  <link
    rel="modulepreload"
    as="script"
    crossOrigin=""
    href="/_nuxt/useAddHeaders.81d953b2.js"
  />
  <link
    rel="modulepreload"
    as="script"
    crossOrigin=""
    href="/_nuxt/useGetPageContent.8cbec180.js"
  />
  <link
    rel="prefetch"
    as="image"
    type="image/svg+xml"
    href="/_nuxt/chevron-down_icon.117109de.svg"
  />
  <link
    rel="prefetch"
    as="image"
    type="image/svg+xml"
    href="/_nuxt/arrow-left_icon.8fddbe8a.svg"
  />
  <link
    rel="prefetch"
    as="script"
    crossOrigin=""
    href="/_nuxt/da.2c4fc51d.js"
  />
  <link
    rel="prefetch"
    as="script"
    crossOrigin=""
    href="/_nuxt/de.89c449f5.js"
  />
  <link
    rel="prefetch"
    as="script"
    crossOrigin=""
    href="/_nuxt/en.bcc92d75.js"
  />
  <link
    rel="prefetch"
    as="script"
    crossOrigin=""
    href="/_nuxt/es.91833969.js"
  />
  <link
    rel="prefetch"
    as="script"
    crossOrigin=""
    href="/_nuxt/fr.01387082.js"
  />
  <link
    rel="prefetch"
    as="script"
    crossOrigin=""
    href="/_nuxt/it.2384f4eb.js"
  />
  <link
    rel="prefetch"
    as="script"
    crossOrigin=""
    href="/_nuxt/nl.74ac6d34.js"
  />
  <link
    rel="prefetch"
    as="script"
    crossOrigin=""
    href="/_nuxt/no.d230d08c.js"
  />
  <link
    rel="prefetch"
    as="script"
    crossOrigin=""
    href="/_nuxt/pl.f55ad452.js"
  />
  <link
    rel="prefetch"
    as="script"
    crossOrigin=""
    href="/_nuxt/pt.cf27f592.js"
  />
  <link
    rel="prefetch"
    as="script"
    crossOrigin=""
    href="/_nuxt/sv.4be09ee2.js"
  />
  <link
    rel="prefetch"
    as="script"
    crossOrigin=""
    href="/_nuxt/3.global-page-ward.10628ede.js"
  />
  <link
    rel="prefetch"
    as="script"
    crossOrigin=""
    href="/_nuxt/4.locale-page-ward.18353be7.js"
  />
  <link
    rel="prefetch"
    as="script"
    crossOrigin=""
    href="/_nuxt/Badge.7f1aa5af.js"
  />
  <link
    rel="prefetch"
    as="script"
    crossOrigin=""
    href="/_nuxt/BigCross.4230cb35.js"
  />
  <link
    rel="prefetch"
    as="script"
    crossOrigin=""
    href="/_nuxt/BigTick.881396da.js"
  />
  <link
    rel="prefetch"
    as="script"
    crossOrigin=""
    href="/_nuxt/ContentSmiley.02f14c29.js"
  />
  <link
    rel="prefetch"
    as="script"
    crossOrigin=""
    href="/_nuxt/DollarBill.348f41a0.js"
  />
  <link
    rel="prefetch"
    as="script"
    crossOrigin=""
    href="/_nuxt/HandSprout.71394480.js"
  />
  <link
    rel="prefetch"
    as="script"
    crossOrigin=""
    href="/_nuxt/HandsCelebrate.edd40d27.js"
  />
  <link
    rel="prefetch"
    as="script"
    crossOrigin=""
    href="/_nuxt/Heart.8a292d32.js"
  />
  <link
    rel="prefetch"
    as="script"
    crossOrigin=""
    href="/_nuxt/PeaceSign.67938273.js"
  />
  <link
    rel="prefetch"
    as="script"
    crossOrigin=""
    href="/_nuxt/PlanetEarth.455e0730.js"
  />
  <link
    rel="prefetch"
    as="script"
    crossOrigin=""
    href="/_nuxt/PlantGrowing.1ce15971.js"
  />
  <link
    rel="prefetch"
    as="script"
    crossOrigin=""
    href="/_nuxt/PuzzlePiece.c40044bb.js"
  />
  <link
    rel="prefetch"
    as="script"
    crossOrigin=""
    href="/_nuxt/RelaxedEmoji.589ed88e.js"
  />
  <link
    rel="prefetch"
    as="script"
    crossOrigin=""
    href="/_nuxt/SadSmiley.2590db79.js"
  />
  <link
    rel="prefetch"
    as="script"
    crossOrigin=""
    href="/_nuxt/TgtgShoppingBag.03b8e57f.js"
  />
  <link
    rel="prefetch"
    as="script"
    crossOrigin=""
    href="/_nuxt/animated_heart_icon.fbe4a875.js"
  />
  <link
    rel="prefetch"
    as="script"
    crossOrigin=""
    href="/_nuxt/animated_shopping_bag_icon.6392921a.js"
  />
  <link
    rel="prefetch"
    as="script"
    crossOrigin=""
    href="/_nuxt/animated_store_icon.67fe0256.js"
  />
  <link
    rel="prefetch"
    as="script"
    crossOrigin=""
    href="/_nuxt/arrow-down_icon.148fa230.js"
  />
  <link
    rel="prefetch"
    as="script"
    crossOrigin=""
    href="/_nuxt/arrow-left_icon.992e15ff.js"
  />
  <link
    rel="prefetch"
    as="script"
    crossOrigin=""
    href="/_nuxt/arrow-right_icon.bcf3b787.js"
  />
  <link
    rel="prefetch"
    as="script"
    crossOrigin=""
    href="/_nuxt/bakeries_icon.d7032917.js"
  />
  <link
    rel="prefetch"
    as="script"
    crossOrigin=""
    href="/_nuxt/bcorp_logo.689e8e15.js"
  />
  <link
    rel="prefetch"
    as="script"
    crossOrigin=""
    href="/_nuxt/bcorp_logo_dark.c85a93c1.js"
  />
  <link
    rel="prefetch"
    as="script"
    crossOrigin=""
    href="/_nuxt/check-circle_icon.e536e3cf.js"
  />
  <link
    rel="prefetch"
    as="script"
    crossOrigin=""
    href="/_nuxt/checkmark_icon.84add9b0.js"
  />
  <link
    rel="prefetch"
    as="script"
    crossOrigin=""
    href="/_nuxt/chevron-down_icon.7ad75833.js"
  />
  <link
    rel="prefetch"
    as="script"
    crossOrigin=""
    href="/_nuxt/chevron-up_icon.d15021b4.js"
  />
  <link
    rel="prefetch"
    as="script"
    crossOrigin=""
    href="/_nuxt/clock_icon.40733065.js"
  />
  <link
    rel="prefetch"
    as="script"
    crossOrigin=""
    href="/_nuxt/close-baseColor_icon.553a57bd.js"
  />
  <link
    rel="prefetch"
    as="script"
    crossOrigin=""
    href="/_nuxt/close-danger_icon.19842fa2.js"
  />
  <link
    rel="prefetch"
    as="script"
    crossOrigin=""
    href="/_nuxt/close_icon.87275f92.js"
  />
  <link
    rel="prefetch"
    as="script"
    crossOrigin=""
    href="/_nuxt/copy-link_logo.437ad862.js"
  />
  <link
    rel="prefetch"
    as="script"
    crossOrigin=""
    href="/_nuxt/external_link.b582e310.js"
  />
  <link
    rel="prefetch"
    as="script"
    crossOrigin=""
    href="/_nuxt/face-serious-baseColor_icon.b334040e.js"
  />
  <link
    rel="prefetch"
    as="script"
    crossOrigin=""
    href="/_nuxt/face-serious-danger_icon.d5057d91.js"
  />
  <link
    rel="prefetch"
    as="script"
    crossOrigin=""
    href="/_nuxt/face-serious_icon.39d26abc.js"
  />
  <link
    rel="prefetch"
    as="script"
    crossOrigin=""
    href="/_nuxt/facebook_logo.023fe70d.js"
  />
  <link
    rel="prefetch"
    as="script"
    crossOrigin=""
    href="/_nuxt/facebook_logo_dark.7ff4259a.js"
  />
  <link
    rel="prefetch"
    as="script"
    crossOrigin=""
    href="/_nuxt/flag_AT.0b0e1c34.js"
  />
  <link
    rel="prefetch"
    as="script"
    crossOrigin=""
    href="/_nuxt/flag_AU.2addfc35.js"
  />
  <link
    rel="prefetch"
    as="script"
    crossOrigin=""
    href="/_nuxt/flag_BE.5244ac82.js"
  />
  <link
    rel="prefetch"
    as="script"
    crossOrigin=""
    href="/_nuxt/flag_CA.1469902a.js"
  />
  <link
    rel="prefetch"
    as="script"
    crossOrigin=""
    href="/_nuxt/flag_CH.64590e4c.js"
  />
  <link
    rel="prefetch"
    as="script"
    crossOrigin=""
    href="/_nuxt/flag_CZ.c85d50bb.js"
  />
  <link
    rel="prefetch"
    as="script"
    crossOrigin=""
    href="/_nuxt/flag_DE.d0d1049b.js"
  />
  <link
    rel="prefetch"
    as="script"
    crossOrigin=""
    href="/_nuxt/flag_DK.a69a9fff.js"
  />
  <link
    rel="prefetch"
    as="script"
    crossOrigin=""
    href="/_nuxt/flag_ES.5fc0abb8.js"
  />
  <link
    rel="prefetch"
    as="script"
    crossOrigin=""
    href="/_nuxt/flag_FR.804d0c8c.js"
  />
  <link
    rel="prefetch"
    as="script"
    crossOrigin=""
    href="/_nuxt/flag_GB.9ef6b604.js"
  />
  <link
    rel="prefetch"
    as="script"
    crossOrigin=""
    href="/_nuxt/flag_IE.e1cdd90a.js"
  />
  <link
    rel="prefetch"
    as="script"
    crossOrigin=""
    href="/_nuxt/flag_IT.c48d3600.js"
  />
  <link
    rel="prefetch"
    as="script"
    crossOrigin=""
    href="/_nuxt/flag_NL.f8b5df64.js"
  />
  <link
    rel="prefetch"
    as="script"
    crossOrigin=""
    href="/_nuxt/flag_NO.79a70ce9.js"
  />
  <link
    rel="prefetch"
    as="script"
    crossOrigin=""
    href="/_nuxt/flag_PL.b5f01b50.js"
  />
  <link
    rel="prefetch"
    as="script"
    crossOrigin=""
    href="/_nuxt/flag_PT.05037921.js"
  />
  <link
    rel="prefetch"
    as="script"
    crossOrigin=""
    href="/_nuxt/flag_RO.4809a74a.js"
  />
  <link
    rel="prefetch"
    as="script"
    crossOrigin=""
    href="/_nuxt/flag_SE.e92838f4.js"
  />
  <link
    rel="prefetch"
    as="script"
    crossOrigin=""
    href="/_nuxt/flag_US.f8125967.js"
  />
  <link
    rel="prefetch"
    as="script"
    crossOrigin=""
    href="/_nuxt/hamburger--closed_icon.f96f659b.js"
  />
  <link
    rel="prefetch"
    as="script"
    crossOrigin=""
    href="/_nuxt/hamburger_icon.0bd3747c.js"
  />
  <link
    rel="prefetch"
    as="script"
    crossOrigin=""
    href="/_nuxt/hotels_icon.ef51fe92.js"
  />
  <link
    rel="prefetch"
    as="script"
    crossOrigin=""
    href="/_nuxt/info_icon.eb19d19e.js"
  />
  <link
    rel="prefetch"
    as="script"
    crossOrigin=""
    href="/_nuxt/instagram_logo.36bf6e5a.js"
  />
  <link
    rel="prefetch"
    as="script"
    crossOrigin=""
    href="/_nuxt/instagram_logo_dark.fb5c66c8.js"
  />
  <link
    rel="prefetch"
    as="script"
    crossOrigin=""
    href="/_nuxt/linkedin_logo.4b2c11df.js"
  />
  <link
    rel="prefetch"
    as="script"
    crossOrigin=""
    href="/_nuxt/linkedin_logo_dark.f296cfd5.js"
  />
  <link
    rel="prefetch"
    as="script"
    crossOrigin=""
    href="/_nuxt/megaphone_icon.9309bf3d.js"
  />
  <link
    rel="prefetch"
    as="script"
    crossOrigin=""
    href="/_nuxt/megaphone_icon_background.20a7d3d9.js"
  />
  <link
    rel="prefetch"
    as="script"
    crossOrigin=""
    href="/_nuxt/platform_logo.06c66206.js"
  />
  <link
    rel="prefetch"
    as="script"
    crossOrigin=""
    href="/_nuxt/restaurants_icon.fa3c6193.js"
  />
  <link
    rel="prefetch"
    as="script"
    crossOrigin=""
    href="/_nuxt/skeleton.b8eb5b68.js"
  />
  <link
    rel="prefetch"
    as="script"
    crossOrigin=""
    href="/_nuxt/star_icon.aa103eaf.js"
  />
  <link
    rel="prefetch"
    as="script"
    crossOrigin=""
    href="/_nuxt/store_icon.6169f79e.js"
  />
  <link
    rel="prefetch"
    as="script"
    crossOrigin=""
    href="/_nuxt/supermarkets_icon.2c8e65cd.js"
  />
  <link
    rel="prefetch"
    as="script"
    crossOrigin=""
    href="/_nuxt/tgtglabel-logo.172018e6.js"
  />
  <link
    rel="prefetch"
    as="script"
    crossOrigin=""
    href="/_nuxt/twitter_logo.defaeef6.js"
  />
  <link
    rel="prefetch"
    as="script"
    crossOrigin=""
    href="/_nuxt/twitter_logo_dark.faa7c92e.js"
  />
  <link
    rel="prefetch"
    as="script"
    crossOrigin=""
    href="/_nuxt/warning_icon.842632a9.js"
  />
  <link
    rel="prefetch"
    as="script"
    crossOrigin=""
    href="/_nuxt/youtube_logo.85960288.js"
  />
  <link
    rel="prefetch"
    as="script"
    crossOrigin=""
    href="/_nuxt/youtube_logo_dark.a0eb1c38.js"
  />
  <link rel="prefetch" as="style" href="/_nuxt/error-component.c78cd897.css" />
  <link
    rel="prefetch"
    as="script"
    crossOrigin=""
    href="/_nuxt/error-component.8ca17521.js"
  />
  <link
    rel="prefetch"
    as="image"
    type="image/png"
    href="/_nuxt/tgtg-logo-simple.144d591d.png"
  />
  <link
    rel="prefetch"
    as="image"
    type="image/svg+xml"
    href="/_nuxt/BigTick.6962ec4e.svg"
  />
  <link
    rel="prefetch"
    as="image"
    type="image/png"
    href="/_nuxt/referral_placeholder.55a7daa6.png"
  />
  <link
    rel="prefetch"
    as="image"
    type="image/svg+xml"
    href="/_nuxt/arrow-right_icon.4cf6c42a.svg"
  />
  <link
    rel="prefetch"
    as="image"
    type="image/jpeg"
    href="/_nuxt/cms_testimonial_1.26486818.jpeg"
  />
  <link
    rel="prefetch"
    as="image"
    type="image/jpeg"
    href="/_nuxt/cms_testimonial_2.88116fa3.jpeg"
  />
  <link
    rel="prefetch"
    as="image"
    type="image/jpeg"
    href="/_nuxt/cms_testimonial_3.0061f64f.jpeg"
  />
  <link
    rel="prefetch"
    as="image"
    type="image/jpeg"
    href="/_nuxt/cms_testimonial_4.ee323689.jpeg"
  />
  <link
    rel="prefetch"
    as="image"
    type="image/svg+xml"
    href="/_nuxt/google_badge_da.5d54d84a.svg"
  />
  <link
    rel="prefetch"
    as="image"
    type="image/svg+xml"
    href="/_nuxt/google_badge_de.538b37d0.svg"
  />
  <link
    rel="prefetch"
    as="image"
    type="image/svg+xml"
    href="/_nuxt/google_badge_en.dd12d068.svg"
  />
  <link
    rel="prefetch"
    as="image"
    type="image/svg+xml"
    href="/_nuxt/google_badge_es.6c200b1a.svg"
  />
  <link
    rel="prefetch"
    as="image"
    type="image/svg+xml"
    href="/_nuxt/google_badge_fr.a4f7678a.svg"
  />
  <link
    rel="prefetch"
    as="image"
    type="image/svg+xml"
    href="/_nuxt/google_badge_it.1e34ac61.svg"
  />
  <link
    rel="prefetch"
    as="image"
    type="image/svg+xml"
    href="/_nuxt/google_badge_nl.001a3dfb.svg"
  />
  <link
    rel="prefetch"
    as="image"
    type="image/svg+xml"
    href="/_nuxt/google_badge_no.796bf44e.svg"
  />
  <link
    rel="prefetch"
    as="image"
    type="image/svg+xml"
    href="/_nuxt/google_badge_pl.b2764500.svg"
  />
  <link
    rel="prefetch"
    as="image"
    type="image/svg+xml"
    href="/_nuxt/google_badge_pt.6233155e.svg"
  />
  <link
    rel="prefetch"
    as="image"
    type="image/svg+xml"
    href="/_nuxt/google_badge_sv.d4e13eee.svg"
  />
  <link
    rel="prefetch"
    as="image"
    type="image/svg+xml"
    href="/_nuxt/huawei_badge_da.02885df6.svg"
  />
  <link
    rel="prefetch"
    as="image"
    type="image/svg+xml"
    href="/_nuxt/huawei_badge_de.e2f87158.svg"
  />
  <link
    rel="prefetch"
    as="image"
    type="image/svg+xml"
    href="/_nuxt/huawei_badge_es.3948ce12.svg"
  />
  <link
    rel="prefetch"
    as="image"
    type="image/svg+xml"
    href="/_nuxt/huawei_badge_fr.89fbd9c0.svg"
  />
  <link
    rel="prefetch"
    as="image"
    type="image/svg+xml"
    href="/_nuxt/huawei_badge_it.5b15b31c.svg"
  />
  <link
    rel="prefetch"
    as="image"
    type="image/svg+xml"
    href="/_nuxt/huawei_badge_nl.b5531a0b.svg"
  />
  <link
    rel="prefetch"
    as="image"
    type="image/svg+xml"
    href="/_nuxt/huawei_badge_pl.9226dd32.svg"
  />
  <link
    rel="prefetch"
    as="image"
    type="image/svg+xml"
    href="/_nuxt/huawei_badge_pt.20b84210.svg"
  />
  <link
    rel="prefetch"
    as="image"
    type="image/svg+xml"
    href="/_nuxt/ios_badge_da.c8e421bf.svg"
  />
  <link
    rel="prefetch"
    as="image"
    type="image/svg+xml"
    href="/_nuxt/ios_badge_de.4f2967e1.svg"
  />
  <link
    rel="prefetch"
    as="image"
    type="image/svg+xml"
    href="/_nuxt/ios_badge_en.a26fc5b3.svg"
  />
  <link
    rel="prefetch"
    as="image"
    type="image/svg+xml"
    href="/_nuxt/ios_badge_es.e4d7c2c1.svg"
  />
  <link
    rel="prefetch"
    as="image"
    type="image/svg+xml"
    href="/_nuxt/ios_badge_fr.86b6a05f.svg"
  />
  <link
    rel="prefetch"
    as="image"
    type="image/svg+xml"
    href="/_nuxt/ios_badge_it.ec8e9566.svg"
  />
  <link
    rel="prefetch"
    as="image"
    type="image/svg+xml"
    href="/_nuxt/ios_badge_nl.cfe2cffb.svg"
  />
  <link
    rel="prefetch"
    as="image"
    type="image/svg+xml"
    href="/_nuxt/ios_badge_no.3fb09d42.svg"
  />
  <link
    rel="prefetch"
    as="image"
    type="image/svg+xml"
    href="/_nuxt/ios_badge_pl.da8b2cc1.svg"
  />
  <link
    rel="prefetch"
    as="image"
    type="image/svg+xml"
    href="/_nuxt/ios_badge_pt.d23b8044.svg"
  />
  <link
    rel="prefetch"
    as="image"
    type="image/svg+xml"
    href="/_nuxt/ios_badge_sv.eb249aad.svg"
  />
  <link
    rel="prefetch"
    as="image"
    type="image/svg+xml"
    href="/_nuxt/tgtg_logo.70534928.svg"
  />
  <link rel="stylesheet" href="../../_nuxt/entry.f1d14e5f.css" />
  <link rel="stylesheet" href="../../_nuxt/TgtgAsideHero.43b76257.css" />
  <link
    rel="stylesheet"
    href="../../_nuxt/TgtgDownloadAppQrModal.f717836d.css"
  />
  <link
    rel="stylesheet"
    href="../../_nuxt/TgtgLanguageChangePopUp.5cc93a59.css"
  />
  <link rel="stylesheet" href="../../_nuxt/TgtgAccessibleSelect.86e59d48.css" />
  <link rel="stylesheet" href="../../_nuxt/TgtgBackgroundHero.32bfaa07.css" />
  <link rel="stylesheet" href="../../_nuxt/TgtgCheckBox.2bd46282.css" />
  <link rel="stylesheet" href="../../_nuxt/TgtgContactForm.a03a322c.css" />
  <link rel="stylesheet" href="../../_nuxt/TgtgSelect.7550e839.css" />
  <link rel="stylesheet" href="../../_nuxt/TgtgSocialShare.292d5883.css" />
  <link rel="stylesheet" href="../../_nuxt/TgtgLeadRequestForm.5460632e.css" />
  <link rel="stylesheet" href="../../_nuxt/TgtgLister.0b814ffb.css" />
  <link rel="stylesheet" href="../../_nuxt/TgtgHeroHeading.798366aa.css" />
  <link rel="stylesheet" href="../../_nuxt/TgtgPagination.7e295d65.css" />
  <link rel="stylesheet" href="../../_nuxt/TgtgSliderPromo.f6a2bf32.css" />
  <link rel="stylesheet" href="../../_nuxt/TgtgFAQ.3acbdacc.css" />
  <link
    rel="stylesheet"
    href="../../_nuxt/TgtgSlottedHeroDesktopImage.3d661a94.css"
  />
  <link rel="stylesheet" href="../../_nuxt/TgtgTestimonialCard.47e075a1.css" />
  <link rel="stylesheet" href="../../_nuxt/TgtgWysiwyg.a5bff456.css" />
  <style
    dangerouslySetInnerHTML={{
      __html:
        ".fade-in-out-enter-active[data-v-b12d44a8],.fade-in-out-leave-active[data-v-b12d44a8]{transition:all .4s}.fade-in-out-enter-from[data-v-b12d44a8],.fade-in-out-leave-to[data-v-b12d44a8]{filter:blur(5px);opacity:0}"
    }}
  />
  <style
    dangerouslySetInnerHTML={{
      __html:
        ".tgtg[data-v-12c90a19]{display:flex;flex-direction:column;min-height:100vh}.tgtg__content[data-v-12c90a19]{background-color:#056365;flex-grow:1}.tgtg__content[data-v-12c90a19]> :first-child{padding-top:var(--header-height,96px)}@media (min-width:1280px){.tgtg__content[data-v-12c90a19]> :first-child{padding-top:var(--header-height,123px)}}@media print{.tgtg__content[data-v-12c90a19]> :first-child{padding-top:2rem}}@media print and (min-width:1280px){.tgtg__content[data-v-12c90a19]> :first-child{padding-top:2rem}}"
    }}
  />
  <style
    dangerouslySetInnerHTML={{
      __html:
        "@media (min-width:1280px){.tgtg-consumer__partners[data-v-1cd515bb]{padding-bottom:14rem}}"
    }}
  />
  <style
    dangerouslySetInnerHTML={{
      __html:
        ".tgtg-alerts[data-v-696db267]{bottom:0;display:flex;flex-direction:column;height:auto;justify-content:center;left:1rem;margin-bottom:.5rem;position:fixed;right:1rem;width:auto;z-index:100}"
    }}
  />
  <div id="__nuxt">
    {/*[*/}
    {/*[*/}
    <div className="tgtg" data-v-12c90a19="">
      {/*[*/}
      {/**/}
      <header className="tgtg-header" data-v-d1637ef9="">
        <div className="tgtg-header__wrapper tgtg-wrapper" data-v-d1637ef9="">
          <a
            href="/en-gb"
            className="tgtg-header__logo"
            rel="preload"
            data-v-d1637ef9=""
          >
            <img
              src="../../_nuxt/tgtg_logo.70534928.svg"
              alt="Too Good To Go logo"
              className="tgtg-header__logo__image"
              data-v-d1637ef9=""
            />
          </a>
          <div
            className="tgtg-toggle tgtg-header__segment-toggle"
            data-v-d1637ef9=""
            data-v-97ebdf30=""
          >
            {/*[*/}
            <div
              className="tgtg-toggle__option tgtg-toggle__option--selected"
              data-v-97ebdf30=""
            >
              <label
                className="tgtg-toggle__label"
                htmlFor="toggle_navigation_consumer_button_label"
                data-v-97ebdf30=""
              >
                User
              </label>
              <input
                type="radio"
                className="tgtg-toggle__input"
                name="segment_toggle"
                id="toggle_navigation_consumer_button_label"
                defaultValue="user"
                defaultChecked=""
                data-v-97ebdf30=""
              />
            </div>
            <div className="tgtg-toggle__option" data-v-97ebdf30="">
              <label
                className="tgtg-toggle__label"
                htmlFor="toggle_navigation_business_button_label"
                data-v-97ebdf30=""
              >
                Business{" "}
              </label>
              <input
                type="radio"
                className="tgtg-toggle__input"
                name="segment_toggle"
                id="toggle_navigation_business_button_label"
                defaultValue="business"
                data-v-97ebdf30=""
              />
            </div>
            {/*]*/}
          </div>
          <nav
            className="tgtg-header__nav tgtg-header__nav--links"
            data-v-d1637ef9=""
          >
            <ul className="tgtg-header__nav__list" data-v-d1637ef9="">
              {/*[*/}
              <li
                className="tgtg-header__nav__link"
                style={{ minWidth: 125 }}
                data-v-d1637ef9=""
              >
                <a
                  href="/en-gb/initiative"
                  className="tgtg-header__nav__link__item"
                  data-v-d1637ef9=""
                >
                  Initiatives
                </a>
              </li>
              {/*]*/}
            </ul>
          </nav>
          <nav
            className="tgtg-header__nav tgtg-header__nav--ctas"
            data-v-d1637ef9=""
          >
            <ul className="tgtg-header__nav__list" data-v-d1637ef9="">
              {/*[*/}
              <li className="tgtg-header__nav__link" data-v-d1637ef9="">
                <button
                  className="tgtg-header__cta tgtg-button"
                  type="button"
                  data-v-d1637ef9=""
                >
                  Download the app
                </button>
              </li>
              {/*]*/}
            </ul>
          </nav>
          <div
            className="tgtg-select tgtg-header__lang-selector"
            data-v-d1637ef9=""
            data-v-3a7e8e1e=""
          >
            <div className="tgtg-select__selector" data-v-3a7e8e1e="">
              <span className="tgtg-select__selector__label" data-v-3a7e8e1e="">
                United Kingdom
              </span>
              <img
                className="tgtg-icon tgtg-select__selector__trigger"
                src="../../_nuxt/chevron-down_icon.117109de.svg"
                alt="chevron-down_icon"
                data-v-3a7e8e1e=""
              />
            </div>
            <div className="tgtg-select__list__wrapper" data-v-3a7e8e1e="">
              <ul className="tgtg-select__list" data-v-3a7e8e1e="">
                {/*[*/}
                <li className="tgtg-select__item" data-v-3a7e8e1e="">
                  <div className="tgtg-select__value" data-v-3a7e8e1e="">
                    Austria
                  </div>
                </li>
                <li className="tgtg-select__item" data-v-3a7e8e1e="">
                  {/*[*/}
                  <div
                    className="tgtg-select__value tgtg-select__value--parent"
                    data-v-3a7e8e1e=""
                  >
                    Belgium
                  </div>
                  <ul className="tgtg-select__sub-list" data-v-3a7e8e1e="">
                    {/*[*/}
                    <li className="tgtg-select__sub-item" data-v-3a7e8e1e="">
                      <div className="tgtg-select__value" data-v-3a7e8e1e="">
                        Flemish
                      </div>
                    </li>
                    <li className="tgtg-select__sub-item" data-v-3a7e8e1e="">
                      <div className="tgtg-select__value" data-v-3a7e8e1e="">
                        French
                      </div>
                    </li>
                    {/*]*/}
                  </ul>
                  {/*]*/}
                </li>
                <li className="tgtg-select__item" data-v-3a7e8e1e="">
                  {/*[*/}
                  <div
                    className="tgtg-select__value tgtg-select__value--parent"
                    data-v-3a7e8e1e=""
                  >
                    Canada
                  </div>
                  <ul className="tgtg-select__sub-list" data-v-3a7e8e1e="">
                    {/*[*/}
                    <li className="tgtg-select__sub-item" data-v-3a7e8e1e="">
                      <div className="tgtg-select__value" data-v-3a7e8e1e="">
                        English
                      </div>
                    </li>
                    <li className="tgtg-select__sub-item" data-v-3a7e8e1e="">
                      <div className="tgtg-select__value" data-v-3a7e8e1e="">
                        French
                      </div>
                    </li>
                    {/*]*/}
                  </ul>
                  {/*]*/}
                </li>
                <li className="tgtg-select__item" data-v-3a7e8e1e="">
                  <div className="tgtg-select__value" data-v-3a7e8e1e="">
                    Denmark
                  </div>
                </li>
                <li className="tgtg-select__item" data-v-3a7e8e1e="">
                  <div className="tgtg-select__value" data-v-3a7e8e1e="">
                    France
                  </div>
                </li>
                <li className="tgtg-select__item" data-v-3a7e8e1e="">
                  <div className="tgtg-select__value" data-v-3a7e8e1e="">
                    Germany
                  </div>
                </li>
                <li className="tgtg-select__item" data-v-3a7e8e1e="">
                  <div className="tgtg-select__value" data-v-3a7e8e1e="">
                    Ireland
                  </div>
                </li>
                <li className="tgtg-select__item" data-v-3a7e8e1e="">
                  <div className="tgtg-select__value" data-v-3a7e8e1e="">
                    Italy
                  </div>
                </li>
                <li className="tgtg-select__item" data-v-3a7e8e1e="">
                  <div className="tgtg-select__value" data-v-3a7e8e1e="">
                    Norway
                  </div>
                </li>
                <li className="tgtg-select__item" data-v-3a7e8e1e="">
                  <div className="tgtg-select__value" data-v-3a7e8e1e="">
                    Poland
                  </div>
                </li>
                <li className="tgtg-select__item" data-v-3a7e8e1e="">
                  <div className="tgtg-select__value" data-v-3a7e8e1e="">
                    Portugal
                  </div>
                </li>
                <li className="tgtg-select__item" data-v-3a7e8e1e="">
                  <div className="tgtg-select__value" data-v-3a7e8e1e="">
                    Spain
                  </div>
                </li>
                <li className="tgtg-select__item" data-v-3a7e8e1e="">
                  <div className="tgtg-select__value" data-v-3a7e8e1e="">
                    Sweden
                  </div>
                </li>
                <li className="tgtg-select__item" data-v-3a7e8e1e="">
                  {/*[*/}
                  <div
                    className="tgtg-select__value tgtg-select__value--parent"
                    data-v-3a7e8e1e=""
                  >
                    Switzerland
                  </div>
                  <ul className="tgtg-select__sub-list" data-v-3a7e8e1e="">
                    {/*[*/}
                    <li className="tgtg-select__sub-item" data-v-3a7e8e1e="">
                      <div className="tgtg-select__value" data-v-3a7e8e1e="">
                        German
                      </div>
                    </li>
                    <li className="tgtg-select__sub-item" data-v-3a7e8e1e="">
                      <div className="tgtg-select__value" data-v-3a7e8e1e="">
                        French
                      </div>
                    </li>
                    <li className="tgtg-select__sub-item" data-v-3a7e8e1e="">
                      <div className="tgtg-select__value" data-v-3a7e8e1e="">
                        Italian
                      </div>
                    </li>
                    {/*]*/}
                  </ul>
                  {/*]*/}
                </li>
                <li className="tgtg-select__item" data-v-3a7e8e1e="">
                  <div className="tgtg-select__value" data-v-3a7e8e1e="">
                    The Netherlands
                  </div>
                </li>
                <li className="tgtg-select__item" data-v-3a7e8e1e="">
                  <div
                    className="tgtg-select__value tgtg-select__value--selected"
                    data-v-3a7e8e1e=""
                  >
                    United Kingdom
                  </div>
                </li>
                <li className="tgtg-select__item" data-v-3a7e8e1e="">
                  <div className="tgtg-select__value" data-v-3a7e8e1e="">
                    United States
                  </div>
                </li>
                {/*]*/}
              </ul>
            </div>
          </div>
        </div>
        <div className="tgtg-header__menu-trigger" data-v-d1637ef9="">
          {/*?xml version="1.0" encoding="utf-8"?*/}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={25}
            height={20}
            viewBox="0 0 25 20"
          >
            <path
              id="hamburger-line-1"
              fill="none"
              stroke="#000000"
              strokeWidth={3}
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M2,2 l10.542,0 L23,2"
            />
            <path
              id="hamburger-line-2"
              fill="none"
              stroke="#000000"
              strokeWidth={3}
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M2,10 l10.542,0 h10.542"
            />
            <path
              id="hamburger-line-3"
              fill="none"
              stroke="#000000"
              strokeWidth={3}
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M2,18 l10.542-0 L23,18"
            />
          </svg>
        </div>
      </header>
      {/*]*/}
      <main className="tgtg__content" data-v-12c90a19="">
        {/*[*/}
        {/*[*/}
        {/*[*/}
        <section
          className="tgtg-bg-hero tgtg-bg-hero--is-mobile-centered tgtg-consumer__hero tgtg-wrapper"
          data-v-1cd515bb=""
          data-v-b1439f66=""
        >
          <div className="tgtg-bg-hero__wrapper" data-v-b1439f66="">
            <div className="tgtg-content-wrapper" data-v-b1439f66="">
              <div
                className="tgtg-hero-heading tgtg-hero-heading--center tgtg-hero-heading--large tgtg-hero-heading--null tgtg-bg-hero__heading"
                data-v-b1439f66=""
                data-v-55260b66=""
              >
                <h1
                  className="tgtg-hero-heading__heading-content tgtg-hero-heading__heading-content--bold"
                  data-v-55260b66=""
                >
                  {/*[*/}
                  <span className="tgtg-hero-heading__plain" data-v-55260b66="">
                    Save{" "}
                  </span>
                  <span
                    className="tgtg-hero-heading__highlight"
                    data-v-55260b66=""
                  >
                    the planet
                  </span>
                  <span className="tgtg-hero-heading__plain" data-v-55260b66="">
                    {" "}
                    at closing time
                  </span>
                  {/*]*/}
                </h1>
              </div>
              {/**/}
              {/**/}
              {/*[*/}
              <ul
                className="tgtg-app-market-logos"
                data-v-1cd515bb=""
                data-v-f5bc2ac9=""
              >
                <li
                  className="tgtg-app-market-logos__market"
                  data-v-f5bc2ac9=""
                >
                  <a
                    href="https://app.tgtg.to/jBEOve"
                    className="tgtg-app-market-logos__market__link"
                    data-v-f5bc2ac9=""
                  >
                    <img
                      src="../../_nuxt/ios_badge_en.a26fc5b3.svg"
                      alt="appStore"
                      className="tgtg-app-market-logos__market__logo"
                      data-v-f5bc2ac9=""
                    />
                  </a>
                </li>
                <li
                  className="tgtg-app-market-logos__market"
                  data-v-f5bc2ac9=""
                >
                  <a
                    href="https://app.tgtg.to/jBEOve"
                    className="tgtg-app-market-logos__market__link"
                    data-v-f5bc2ac9=""
                  >
                    <img
                      src="../../_nuxt/google_badge_en.dd12d068.svg"
                      alt="googlePlay"
                      className="tgtg-app-market-logos__market__logo"
                      data-v-f5bc2ac9=""
                    />
                  </a>
                </li>
                <li
                  className="tgtg-app-market-logos__market"
                  data-v-f5bc2ac9=""
                >
                  <a
                    href="https://apps.apple.com/GB/app/id1060683933?mt=8"
                    className="tgtg-app-market-logos__market__link"
                    data-v-f5bc2ac9=""
                  >
                    <img
                      src="../../_nuxt/huawei_badge_de.e2f87158.svg"
                      alt="appGallery"
                      className="tgtg-app-market-logos__market__logo"
                      data-v-f5bc2ac9=""
                    />
                  </a>
                </li>
              </ul>
              {/*]*/}
            </div>
          </div>
        </section>
        <div
          className="tgtg-image-promo tgtg-image-promo--light-on-dark tgtg-image-promo--reverse"
          data-v-1cd515bb=""
          data-v-32f65583=""
        >
          <div className="tgtg-wrapper" data-v-32f65583="">
            <div className="tgtg-content-wrapper" data-v-32f65583="">
              <div
                className="tgtg-image-promo__wrapper"
                style={{ paddingBottom: "5rem" }}
                data-v-32f65583=""
              >
                <div className="tgtg-image-promo__copy" data-v-32f65583="">
                  <div
                    className="tgtg-hero-heading tgtg-hero-heading--center tgtg-hero-heading--medium tgtg-hero-heading--null tgtg-image-promo__heading"
                    data-v-32f65583=""
                    data-v-55260b66=""
                  >
                    <h2
                      className="tgtg-hero-heading__heading-content"
                      data-v-55260b66=""
                    >
                      {/*[*/}
                      <span
                        className="tgtg-hero-heading__plain"
                        data-v-55260b66=""
                      >
                        2.5 BILLION TONNES OF{" "}
                      </span>
                      <span
                        className="tgtg-hero-heading__highlight"
                        data-v-55260b66=""
                      >
                        FOOD
                      </span>
                      <span
                        className="tgtg-hero-heading__plain"
                        data-v-55260b66=""
                      >
                        {" "}
                        IS{" "}
                      </span>
                      <span
                        className="tgtg-hero-heading__highlight"
                        data-v-55260b66=""
                      >
                        WASTED
                      </span>
                      <span
                        className="tgtg-hero-heading__plain"
                        data-v-55260b66=""
                      >
                        {" "}
                        EVERY YEAR
                      </span>
                      {/*]*/}
                    </h2>
                  </div>
                  <p
                    className="tgtg-image-promo__description"
                    data-v-32f65583=""
                  >
                    We dream of a planet with no food waste. Every day, unsold
                    food from your favorite restaurants, grocery stores, cafés,
                    and shops goes to waste simply because it hasn’t sold in
                    time. The Too Good To Go app makes it easy to make a
                    difference, allowing you to save Surprise Bags of good food
                    at an even better price.
                  </p>
                  {/*[*/}
                  {/*]*/}
                  <div
                    className="tgtg-image-promo__cta__wrapper"
                    data-v-32f65583=""
                  >
                    {/*[*/}
                    {/*[*/}
                    <button
                      className="tgtg-image-promo__cta tgtg-button"
                      data-v-32f65583=""
                    >
                      Download the app
                    </button>
                    {/*]*/}
                    {/*]*/}
                  </div>
                </div>
                <div className="tgtg-image-promo__image" data-v-32f65583="">
                  <picture data-v-32f65583="">
                    <source
                      srcSet="https://tgtg-mkt-cms-prod.s3.eu-west-1.amazonaws.com/38989/hm11NC6O2chXhRjm"
                      media="(max-width: 768px)"
                      data-v-32f65583=""
                    />
                    <img
                      className="tgtg-tab-cards__image-main"
                      src="https://tgtg-mkt-cms-prod.s3.eu-west-1.amazonaws.com/38989/hm11NC6O2chXhRjm"
                      data-v-32f65583=""
                    />
                  </picture>
                </div>
              </div>
              {/**/}
            </div>
          </div>
        </div>
        <div
          className="tgtg-stats-promo tgtg-stats-promo--slim tgtg-wrapper"
          data-v-1cd515bb=""
          data-v-2e4b4287=""
        >
          <div
            className="tgtg-stats-promo--large tgtg-content-wrapper"
            data-v-2e4b4287=""
          >
            {/**/}
            {/**/}
            <div
              className="tgtg-slider tgtg-slider--1-visible-slides tgtg-stats-promo__slider"
              data-v-2e4b4287=""
              data-v-e5d97b2e=""
            >
              <div className="tgtg-slider__mask" data-v-e5d97b2e="">
                <ul
                  id="tgtg-carousel_tgtgStatsPromo"
                  aria-roledescription="slide"
                  aria-live="polite"
                  className="tgtg-slider__list tgtg-content-wrapper"
                  style={{ transform: "translateX(-0px)" }}
                  data-v-e5d97b2e=""
                >
                  {/*[*/}
                  <li
                    aria-hidden="false"
                    className="tgtg-slider__slide"
                    data-v-e5d97b2e=""
                  >
                    {/*[*/}
                    <img
                      className="tgtg-icon tgtg-stats-promo__icon"
                      src="../../_nuxt/animated_heart_icon.4d3e4e96.svg"
                      alt="animated_heart_icon"
                      data-v-2e4b4287=""
                    />
                    <p className="tgtg-stats-promo__figure" data-v-2e4b4287="">
                      <span
                        className="tgtg-stats-promo__figure__value"
                        data-v-2e4b4287=""
                      >
                        15.3M
                      </span>
                    </p>
                    <p
                      className="tgtg-stats-promo__extra-info"
                      data-v-2e4b4287=""
                    >
                      Users of the Too Good To Go app
                    </p>
                    {/*]*/}
                  </li>
                  <li
                    aria-hidden="true"
                    className="tgtg-slider__slide"
                    data-v-e5d97b2e=""
                  >
                    {/*[*/}
                    <img
                      className="tgtg-icon tgtg-stats-promo__icon"
                      src="../../_nuxt/animated_store_icon.e6690cfa.svg"
                      alt="animated_store_icon"
                      data-v-2e4b4287=""
                    />
                    <p className="tgtg-stats-promo__figure" data-v-2e4b4287="">
                      <span
                        className="tgtg-stats-promo__figure__value"
                        data-v-2e4b4287=""
                      >
                        28,736
                      </span>
                    </p>
                    <p
                      className="tgtg-stats-promo__extra-info"
                      data-v-2e4b4287=""
                    >
                      Active businesses on the platform
                    </p>
                    {/*]*/}
                  </li>
                  <li
                    aria-hidden="true"
                    className="tgtg-slider__slide"
                    data-v-e5d97b2e=""
                  >
                    {/*[*/}
                    <img
                      className="tgtg-icon tgtg-stats-promo__icon"
                      src="../../_nuxt/animated_shopping_bag_icon.fcda56a8.svg"
                      alt="animated_shopping_bag_icon"
                      data-v-2e4b4287=""
                    />
                    <p className="tgtg-stats-promo__figure" data-v-2e4b4287="">
                      <span
                        className="tgtg-stats-promo__figure__value"
                        data-v-2e4b4287=""
                      >
                        39M
                      </span>
                    </p>
                    <p
                      className="tgtg-stats-promo__extra-info"
                      data-v-2e4b4287=""
                    >
                      Surprise Bags saved and counting
                    </p>
                    {/*]*/}
                  </li>
                  {/*]*/}
                </ul>
              </div>
              {/*[*/}
              <div className="tgtg-slider__controls" data-v-e5d97b2e="">
                <button
                  role="button"
                  type="button"
                  aria-controls="tgtg-carousel_tgtgStatsPromo"
                  disabled=""
                  className="tgtg-slider__controls__trigger tgtg-slider__controls__trigger--back"
                  data-v-e5d97b2e=""
                >
                  <span
                    className="tgtg-slider__controls__trigger-accessible-txt"
                    data-v-e5d97b2e=""
                  >
                    app_general_accessibility_back_button
                  </span>
                </button>
                <button
                  role="button"
                  aria-controls="tgtg-carousel_tgtgStatsPromo"
                  className="tgtg-slider__controls__trigger tgtg-slider__controls__trigger--forward"
                  data-v-e5d97b2e=""
                >
                  <span
                    className="tgtg-slider__controls__trigger-accessible-txt"
                    data-v-e5d97b2e=""
                  >
                    app_general_accessibility_forward_button
                  </span>
                </button>
              </div>
              {/*]*/}
            </div>
          </div>
        </div>
        <section
          className="tgtg-partners-section tgtg-consumer__partners"
          data-v-1cd515bb=""
          data-v-ae9cec5b=""
        >
          <div className="tgtg-wrapper" data-v-ae9cec5b="">
            <h4 className="tgtg-partners-section__heading" data-v-ae9cec5b="">
              SOME OF OUR PARTNERS
            </h4>
            <div
              className="tgtg-infinite-carousel tgtg-partners-section__carousel"
              data-v-ae9cec5b=""
              data-v-64e7f016=""
            >
              <ul
                className="tgtg-infinite-carousel__list"
                style={{ transition: "unset", transform: "translateX(-0px)" }}
                data-v-64e7f016=""
              >
                {/*[*/}
                <li
                  className="tgtg-infinite-carousel__slide"
                  data-v-64e7f016=""
                >
                  {/*[*/}
                  <div
                    className="tgtg-partners-section__partner"
                    data-v-ae9cec5b=""
                  >
                    <img
                      className="tgtg-partners-section__partner__logo"
                      src="https://tgtg-mkt-cms-prod.s3.eu-west-1.amazonaws.com/39051/k5juGwS9txb6Xpnz"
                      alt="Toby Carvery"
                      data-v-ae9cec5b=""
                    />
                  </div>
                  {/*]*/}
                </li>
                <li
                  className="tgtg-infinite-carousel__slide"
                  data-v-64e7f016=""
                >
                  {/*[*/}
                  <div
                    className="tgtg-partners-section__partner"
                    data-v-ae9cec5b=""
                  >
                    <img
                      className="tgtg-partners-section__partner__logo"
                      src="https://tgtg-mkt-cms-prod.s3.eu-west-1.amazonaws.com/39052/g20eTxu0MLXxvalB"
                      alt="Harvester"
                      data-v-ae9cec5b=""
                    />
                  </div>
                  {/*]*/}
                </li>
                <li
                  className="tgtg-infinite-carousel__slide"
                  data-v-64e7f016=""
                >
                  {/*[*/}
                  <div
                    className="tgtg-partners-section__partner"
                    data-v-ae9cec5b=""
                  >
                    <img
                      className="tgtg-partners-section__partner__logo"
                      src="https://tgtg-mkt-cms-prod.s3.eu-west-1.amazonaws.com/16783/FbT1uaN9HvIWG5Sg"
                      alt="Pret"
                      data-v-ae9cec5b=""
                    />
                  </div>
                  {/*]*/}
                </li>
                <li
                  className="tgtg-infinite-carousel__slide"
                  data-v-64e7f016=""
                >
                  {/*[*/}
                  <div
                    className="tgtg-partners-section__partner"
                    data-v-ae9cec5b=""
                  >
                    <img
                      className="tgtg-partners-section__partner__logo"
                      src="https://tgtg-mkt-cms-prod.s3.eu-west-1.amazonaws.com/18218/cvzoOgLgr2VskNVU"
                      alt="Morrisons"
                      data-v-ae9cec5b=""
                    />
                  </div>
                  {/*]*/}
                </li>
                <li
                  className="tgtg-infinite-carousel__slide"
                  data-v-64e7f016=""
                >
                  {/*[*/}
                  <div
                    className="tgtg-partners-section__partner"
                    data-v-ae9cec5b=""
                  >
                    <img
                      className="tgtg-partners-section__partner__logo"
                      src="https://tgtg-mkt-cms-prod.s3.eu-west-1.amazonaws.com/1513/TnaeL1SDjiuEAsP0"
                      alt="Costa"
                      data-v-ae9cec5b=""
                    />
                  </div>
                  {/*]*/}
                </li>
                <li
                  className="tgtg-infinite-carousel__slide"
                  data-v-64e7f016=""
                >
                  {/*[*/}
                  <div
                    className="tgtg-partners-section__partner"
                    data-v-ae9cec5b=""
                  >
                    <img
                      className="tgtg-partners-section__partner__logo"
                      src="https://tgtg-mkt-cms-prod.s3.eu-west-1.amazonaws.com/1512/JYLUsbVhRxpgibMN"
                      data-v-ae9cec5b=""
                    />
                  </div>
                  {/*]*/}
                </li>
                <li
                  className="tgtg-infinite-carousel__slide"
                  data-v-64e7f016=""
                >
                  {/*[*/}
                  <div
                    className="tgtg-partners-section__partner"
                    data-v-ae9cec5b=""
                  >
                    <img
                      className="tgtg-partners-section__partner__logo"
                      src="https://tgtg-mkt-cms-prod.s3.eu-west-1.amazonaws.com/39050/zSSfcFFCwp1OrsxS"
                      alt="YO! Sushi"
                      data-v-ae9cec5b=""
                    />
                  </div>
                  {/*]*/}
                </li>
                <li
                  className="tgtg-infinite-carousel__slide"
                  data-v-64e7f016=""
                >
                  {/*[*/}
                  <div
                    className="tgtg-partners-section__partner"
                    data-v-ae9cec5b=""
                  >
                    <img
                      className="tgtg-partners-section__partner__logo"
                      src="https://tgtg-mkt-cms-prod.s3.eu-west-1.amazonaws.com/1483/uI0HGMuM2xRAOooc"
                      alt="AccorHotels"
                      data-v-ae9cec5b=""
                    />
                  </div>
                  {/*]*/}
                </li>
                <li
                  className="tgtg-infinite-carousel__slide"
                  data-v-64e7f016=""
                >
                  {/*[*/}
                  <div
                    className="tgtg-partners-section__partner"
                    data-v-ae9cec5b=""
                  >
                    <img
                      className="tgtg-partners-section__partner__logo"
                      src="https://tgtg-mkt-cms-prod.s3.eu-west-1.amazonaws.com/39053/IDdWd1u4MQSLDXaZ"
                      alt="Stonehouse Pizza and Carvery"
                      data-v-ae9cec5b=""
                    />
                  </div>
                  {/*]*/}
                </li>
                <li
                  className="tgtg-infinite-carousel__slide"
                  data-v-64e7f016=""
                >
                  {/*[*/}
                  <div
                    className="tgtg-partners-section__partner"
                    data-v-ae9cec5b=""
                  >
                    <img
                      className="tgtg-partners-section__partner__logo"
                      src="https://tgtg-mkt-cms-prod.s3.eu-west-1.amazonaws.com/17718/YcFJz36SOYtXQYaX"
                      alt="Creed"
                      data-v-ae9cec5b=""
                    />
                  </div>
                  {/*]*/}
                </li>
                <li
                  className="tgtg-infinite-carousel__slide"
                  data-v-64e7f016=""
                >
                  {/*[*/}
                  <div
                    className="tgtg-partners-section__partner"
                    data-v-ae9cec5b=""
                  >
                    <img
                      className="tgtg-partners-section__partner__logo"
                      src="https://tgtg-mkt-cms-prod.s3.eu-west-1.amazonaws.com/17717/gskReiHx8XLezL5S"
                      alt="Savona"
                      data-v-ae9cec5b=""
                    />
                  </div>
                  {/*]*/}
                </li>
                <li
                  className="tgtg-infinite-carousel__slide"
                  data-v-64e7f016=""
                >
                  {/*[*/}
                  <div
                    className="tgtg-partners-section__partner"
                    data-v-ae9cec5b=""
                  >
                    <img
                      className="tgtg-partners-section__partner__logo"
                      src="https://tgtg-mkt-cms-prod.s3.eu-west-1.amazonaws.com/17713/ccbSZYPYp29jn2MV"
                      alt="Doisy & Dam"
                      data-v-ae9cec5b=""
                    />
                  </div>
                  {/*]*/}
                </li>
                <li
                  className="tgtg-infinite-carousel__slide"
                  data-v-64e7f016=""
                >
                  {/*[*/}
                  <div
                    className="tgtg-partners-section__partner"
                    data-v-ae9cec5b=""
                  >
                    <img
                      className="tgtg-partners-section__partner__logo"
                      src="https://tgtg-mkt-cms-prod.s3.eu-west-1.amazonaws.com/17714/tg6PmECyrk77BpuX"
                      alt="KraftHeinz"
                      data-v-ae9cec5b=""
                    />
                  </div>
                  {/*]*/}
                </li>
                <li
                  className="tgtg-infinite-carousel__slide"
                  data-v-64e7f016=""
                >
                  {/*[*/}
                  <div
                    className="tgtg-partners-section__partner"
                    data-v-ae9cec5b=""
                  >
                    <img
                      className="tgtg-partners-section__partner__logo"
                      src="https://tgtg-mkt-cms-prod.s3.eu-west-1.amazonaws.com/17715/N44YIJCpaZyw640D"
                      alt="Dunsters & Farm"
                      data-v-ae9cec5b=""
                    />
                  </div>
                  {/*]*/}
                </li>
                <li
                  className="tgtg-infinite-carousel__slide"
                  data-v-64e7f016=""
                >
                  {/*[*/}
                  <div
                    className="tgtg-partners-section__partner"
                    data-v-ae9cec5b=""
                  >
                    <img
                      className="tgtg-partners-section__partner__logo"
                      src="https://tgtg-mkt-cms-prod.s3.eu-west-1.amazonaws.com/17716/6eUuURiaV1dMS05x"
                      alt="Unilever"
                      data-v-ae9cec5b=""
                    />
                  </div>
                  {/*]*/}
                </li>
                <li
                  className="tgtg-infinite-carousel__slide"
                  data-v-64e7f016=""
                >
                  {/*[*/}
                  <div
                    className="tgtg-partners-section__partner"
                    data-v-ae9cec5b=""
                  >
                    <img
                      className="tgtg-partners-section__partner__logo"
                      src="https://tgtg-mkt-cms-prod.s3.eu-west-1.amazonaws.com/18009/7mTEPLJjfQA146Xg"
                      alt="Wenzel's"
                      data-v-ae9cec5b=""
                    />
                  </div>
                  {/*]*/}
                </li>
                <li
                  className="tgtg-infinite-carousel__slide"
                  data-v-64e7f016=""
                >
                  {/*[*/}
                  <div
                    className="tgtg-partners-section__partner"
                    data-v-ae9cec5b=""
                  >
                    <img
                      className="tgtg-partners-section__partner__logo"
                      src="https://tgtg-mkt-cms-prod.s3.eu-west-1.amazonaws.com/18008/X6HjsV3u6Hi68t5w"
                      alt="Lola's"
                      data-v-ae9cec5b=""
                    />
                  </div>
                  {/*]*/}
                </li>
                <li
                  className="tgtg-infinite-carousel__slide"
                  data-v-64e7f016=""
                >
                  {/*[*/}
                  <div
                    className="tgtg-partners-section__partner"
                    data-v-ae9cec5b=""
                  >
                    <img
                      className="tgtg-partners-section__partner__logo"
                      src="https://tgtg-mkt-cms-prod.s3.eu-west-1.amazonaws.com/18007/txzcTR2ZcSnu6dB3"
                      alt="Peggy Porschen"
                      data-v-ae9cec5b=""
                    />
                  </div>
                  {/*]*/}
                </li>
                <li
                  className="tgtg-infinite-carousel__slide"
                  data-v-64e7f016=""
                >
                  {/*[*/}
                  <div
                    className="tgtg-partners-section__partner"
                    data-v-ae9cec5b=""
                  >
                    <img
                      className="tgtg-partners-section__partner__logo"
                      src="https://tgtg-mkt-cms-prod.s3.eu-west-1.amazonaws.com/18006/M9kOc5XpcS6sFZAJ"
                      alt="Konditor"
                      data-v-ae9cec5b=""
                    />
                  </div>
                  {/*]*/}
                </li>
                <li
                  className="tgtg-infinite-carousel__slide"
                  data-v-64e7f016=""
                >
                  {/*[*/}
                  <div
                    className="tgtg-partners-section__partner"
                    data-v-ae9cec5b=""
                  >
                    <img
                      className="tgtg-partners-section__partner__logo"
                      src="https://tgtg-mkt-cms-prod.s3.eu-west-1.amazonaws.com/18005/jhZxxbFSZRINw3hs"
                      alt="Greggs"
                      data-v-ae9cec5b=""
                    />
                  </div>
                  {/*]*/}
                </li>
                <li
                  className="tgtg-infinite-carousel__slide"
                  data-v-64e7f016=""
                >
                  {/*[*/}
                  <div
                    className="tgtg-partners-section__partner"
                    data-v-ae9cec5b=""
                  >
                    <img
                      className="tgtg-partners-section__partner__logo"
                      src="https://tgtg-mkt-cms-prod.s3.eu-west-1.amazonaws.com/18004/J6ARWygDS5XPHRtt"
                      alt="Nisa"
                      data-v-ae9cec5b=""
                    />
                  </div>
                  {/*]*/}
                </li>
                <li
                  className="tgtg-infinite-carousel__slide"
                  data-v-64e7f016=""
                >
                  {/*[*/}
                  <div
                    className="tgtg-partners-section__partner"
                    data-v-ae9cec5b=""
                  >
                    <img
                      className="tgtg-partners-section__partner__logo"
                      src="https://tgtg-mkt-cms-prod.s3.eu-west-1.amazonaws.com/18002/NvYzQCSQL9Hnl6at"
                      alt="Londis"
                      data-v-ae9cec5b=""
                    />
                  </div>
                  {/*]*/}
                </li>
                <li
                  className="tgtg-infinite-carousel__slide"
                  data-v-64e7f016=""
                >
                  {/*[*/}
                  <div
                    className="tgtg-partners-section__partner"
                    data-v-ae9cec5b=""
                  >
                    <img
                      className="tgtg-partners-section__partner__logo"
                      src="https://tgtg-mkt-cms-prod.s3.eu-west-1.amazonaws.com/18001/pQJCF2Luzdsgys8D"
                      alt="Carrefour"
                      data-v-ae9cec5b=""
                    />
                  </div>
                  {/*]*/}
                </li>
                <li
                  className="tgtg-infinite-carousel__slide"
                  data-v-64e7f016=""
                >
                  {/*[*/}
                  <div
                    className="tgtg-partners-section__partner"
                    data-v-ae9cec5b=""
                  >
                    <img
                      className="tgtg-partners-section__partner__logo"
                      src="https://tgtg-mkt-cms-prod.s3.eu-west-1.amazonaws.com/18000/vespEmbGpIe2bEXa"
                      alt="Budgens"
                      data-v-ae9cec5b=""
                    />
                  </div>
                  {/*]*/}
                </li>
                <li
                  className="tgtg-infinite-carousel__slide"
                  data-v-64e7f016=""
                >
                  {/*[*/}
                  <div
                    className="tgtg-partners-section__partner"
                    data-v-ae9cec5b=""
                  >
                    <img
                      className="tgtg-partners-section__partner__logo"
                      src="https://tgtg-mkt-cms-prod.s3.eu-west-1.amazonaws.com/9904/yQ1tASpddg21CbcV"
                      alt="PAUL Promoted"
                      data-v-ae9cec5b=""
                    />
                  </div>
                  {/*]*/}
                </li>
                <li
                  className="tgtg-infinite-carousel__slide"
                  data-v-64e7f016=""
                >
                  {/*[*/}
                  <div
                    className="tgtg-partners-section__partner"
                    data-v-ae9cec5b=""
                  >
                    <img
                      className="tgtg-partners-section__partner__logo"
                      src="https://tgtg-mkt-cms-prod.s3.eu-west-1.amazonaws.com/1515/fBweoh7HcZr7Bptw"
                      alt="Planet Organic"
                      data-v-ae9cec5b=""
                    />
                  </div>
                  {/*]*/}
                </li>
                <li
                  className="tgtg-infinite-carousel__slide"
                  data-v-64e7f016=""
                >
                  {/*[*/}
                  <div
                    className="tgtg-partners-section__partner"
                    data-v-ae9cec5b=""
                  >
                    <img
                      className="tgtg-partners-section__partner__logo"
                      src="https://tgtg-mkt-cms-prod.s3.eu-west-1.amazonaws.com/1516/bVtp36124VmtmmmU"
                      alt="Patisserie Valerie"
                      data-v-ae9cec5b=""
                    />
                  </div>
                  {/*]*/}
                </li>
                {/*]*/}
                {/*[*/}
                {/*]*/}
              </ul>
            </div>
          </div>
        </section>
        <div
          className="tgtg-slider-promo tgtg-slider-promo--image-overlap tgtg-consumer__slider-promo"
          visible-slides={1}
          data-v-1cd515bb=""
          data-v-815364f1=""
        >
          <div className="tgtg-wrapper" data-v-815364f1="">
            <div className="tgtg-content-wrapper" data-v-815364f1="">
              <div
                className="tgtg-slider tgtg-slider--1-visible-slides tgtg-slider-promo__slider"
                data-v-815364f1=""
                data-v-e5d97b2e=""
              >
                <div
                  className="tgtg-slider__mask tgtg-slider__mask--one-slide-visible"
                  data-v-e5d97b2e=""
                >
                  <ul
                    id="tgtg-carousel_tgtg-slider"
                    aria-roledescription="slide"
                    aria-live="polite"
                    className="tgtg-slider__list tgtg-content-wrapper"
                    style={{ transform: "translateX(-0px)" }}
                    data-v-e5d97b2e=""
                  >
                    {/*[*/}
                    <li
                      aria-hidden="false"
                      className="tgtg-slider__slide"
                      data-v-e5d97b2e=""
                    >
                      {/*[*/}
                      <div
                        className="tgtg-slider-promo__slide"
                        data-v-815364f1=""
                      >
                        <div
                          className="tgtg-slider-promo__slide-img-wrapper"
                          data-v-815364f1=""
                        >
                          <picture data-v-815364f1="">
                            <source
                              srcSet="https://tgtg-mkt-cms-prod.s3.eu-west-1.amazonaws.com/37868/MzVkbJ9BhYfdLNm1"
                              media="(max-width: 768px)"
                              data-v-815364f1=""
                            />
                            <img
                              className="tgtg-slider-promo__slide-img-main"
                              src="https://tgtg-mkt-cms-prod.s3.eu-west-1.amazonaws.com/37869/hr67LVr9GcibEAZE"
                              alt="Mobile phone screen showing the Too Good To Go app Discover page with stores in the area, available Surprise Bags and price of each Surprise Bag"
                              data-v-815364f1=""
                            />
                          </picture>
                        </div>
                        <div
                          className="tgtg-slider-promo__slide-wrapper"
                          data-v-815364f1=""
                        >
                          <div
                            className="tgtg-hero-heading tgtg-hero-heading--center tgtg-hero-heading--medium tgtg-hero-heading--null tgtg-slider-promo__slide-title"
                            data-v-815364f1=""
                            data-v-55260b66=""
                          >
                            <h2
                              className="tgtg-hero-heading__heading-content"
                              data-v-55260b66=""
                            >
                              {/*[*/}
                              <span
                                className="tgtg-hero-heading__plain"
                                data-v-55260b66=""
                              >
                                HOW IT{" "}
                              </span>
                              <span
                                className="tgtg-hero-heading__highlight"
                                data-v-55260b66=""
                              >
                                WORKS
                              </span>
                              {/*]*/}
                            </h2>
                          </div>
                          {/**/}
                          <p
                            className="tgtg-slider-promo__slide-description"
                            data-v-815364f1=""
                          >
                            Open the app and find Surprise Bags of food from
                            restaurants and shops near you.
                          </p>
                          {/**/}
                        </div>
                      </div>
                      {/*]*/}
                    </li>
                    <li
                      aria-hidden="true"
                      className="tgtg-slider__slide"
                      data-v-e5d97b2e=""
                    >
                      {/*[*/}
                      <div
                        className="tgtg-slider-promo__slide"
                        data-v-815364f1=""
                      >
                        <div
                          className="tgtg-slider-promo__slide-img-wrapper"
                          data-v-815364f1=""
                        >
                          <picture data-v-815364f1="">
                            <source
                              srcSet="https://tgtg-mkt-cms-prod.s3.eu-west-1.amazonaws.com/37870/6TdekL3utvp5x8n8"
                              media="(max-width: 768px)"
                              data-v-815364f1=""
                            />
                            <img
                              className="tgtg-slider-promo__slide-img-main"
                              src="https://tgtg-mkt-cms-prod.s3.eu-west-1.amazonaws.com/37871/KUQxwqbIxeQwSW9M"
                              alt="A mobile phone showing a Surprise Bag on the Too Good To Go app"
                              data-v-815364f1=""
                            />
                          </picture>
                        </div>
                        <div
                          className="tgtg-slider-promo__slide-wrapper"
                          data-v-815364f1=""
                        >
                          <div
                            className="tgtg-hero-heading tgtg-hero-heading--center tgtg-hero-heading--medium tgtg-hero-heading--null tgtg-slider-promo__slide-title"
                            data-v-815364f1=""
                            data-v-55260b66=""
                          >
                            <h2
                              className="tgtg-hero-heading__heading-content"
                              data-v-55260b66=""
                            >
                              {/*[*/}
                              <span
                                className="tgtg-hero-heading__plain"
                                data-v-55260b66=""
                              >
                                HOW IT{" "}
                              </span>
                              <span
                                className="tgtg-hero-heading__highlight"
                                data-v-55260b66=""
                              >
                                WORKS
                              </span>
                              {/*]*/}
                            </h2>
                          </div>
                          {/**/}
                          <p
                            className="tgtg-slider-promo__slide-description"
                            data-v-815364f1=""
                          >
                            Choose a Surprise Bag from the Discovery page,
                            reserve it and confirm your purchase. Head to the
                            shop at the given pickup time to collect.
                          </p>
                          {/**/}
                        </div>
                      </div>
                      {/*]*/}
                    </li>
                    <li
                      aria-hidden="true"
                      className="tgtg-slider__slide"
                      data-v-e5d97b2e=""
                    >
                      {/*[*/}
                      <div
                        className="tgtg-slider-promo__slide"
                        data-v-815364f1=""
                      >
                        <div
                          className="tgtg-slider-promo__slide-img-wrapper"
                          data-v-815364f1=""
                        >
                          <picture data-v-815364f1="">
                            <source
                              srcSet="https://tgtg-mkt-cms-prod.s3.eu-west-1.amazonaws.com/37872/tVxfhuPs5OfDN2Ij"
                              media="(max-width: 768px)"
                              data-v-815364f1=""
                            />
                            <img
                              className="tgtg-slider-promo__slide-img-main"
                              src="https://tgtg-mkt-cms-prod.s3.eu-west-1.amazonaws.com/37873/3FyAmEUpBUJlhk2a"
                              alt="A mobile phone showing the thank you page of the app with a Too Good To Go bag"
                              data-v-815364f1=""
                            />
                          </picture>
                        </div>
                        <div
                          className="tgtg-slider-promo__slide-wrapper"
                          data-v-815364f1=""
                        >
                          <div
                            className="tgtg-hero-heading tgtg-hero-heading--center tgtg-hero-heading--medium tgtg-hero-heading--null tgtg-slider-promo__slide-title"
                            data-v-815364f1=""
                            data-v-55260b66=""
                          >
                            <h2
                              className="tgtg-hero-heading__heading-content"
                              data-v-55260b66=""
                            >
                              {/*[*/}
                              <span
                                className="tgtg-hero-heading__plain"
                                data-v-55260b66=""
                              >
                                HOW IT{" "}
                              </span>
                              <span
                                className="tgtg-hero-heading__highlight"
                                data-v-55260b66=""
                              >
                                WORKS
                              </span>
                              {/*]*/}
                            </h2>
                          </div>
                          {/**/}
                          <p
                            className="tgtg-slider-promo__slide-description"
                            data-v-815364f1=""
                          >
                            Take your food to go and enjoy. You've just saved a
                            meal from going to waste and done something good for
                            the planet!
                          </p>
                          {/**/}
                        </div>
                      </div>
                      {/*]*/}
                    </li>
                    {/*]*/}
                  </ul>
                </div>
                {/*[*/}
                <div
                  className="tgtg-pagination tgtg-slider__pagination"
                  data-v-e5d97b2e=""
                  data-v-f08e7a3b=""
                >
                  <button
                    aria-label="Go to previous results page"
                    role="button"
                    type="button"
                    disabled=""
                    className="tgtg-pagination__trigger tgtg-pagination__trigger--back"
                    data-v-f08e7a3b=""
                  >
                    <span
                      className="tgtg-pagination__trigger-accessible-txt"
                      data-v-f08e7a3b=""
                    >
                      app_general_accessibility_back_button
                    </span>
                  </button>
                  <ul className="tgtg-pagination__list" data-v-f08e7a3b="">
                    {/*[*/}
                    <li
                      className="tgtg-pagination__list-item"
                      data-v-f08e7a3b=""
                    >
                      <button
                        role="button"
                        type="button"
                        arial-label="app_general_accessibility_back_button 2"
                        className="tgtg-pagination__trigger tgtg-pagination__trigger--active"
                        data-v-f08e7a3b=""
                      >
                        1
                      </button>
                    </li>
                    <li
                      className="tgtg-pagination__list-item"
                      data-v-f08e7a3b=""
                    >
                      <button
                        role="button"
                        type="button"
                        arial-label="app_general_accessibility_back_button 3"
                        className="tgtg-pagination__trigger"
                        data-v-f08e7a3b=""
                      >
                        2
                      </button>
                    </li>
                    <li
                      className="tgtg-pagination__list-item"
                      data-v-f08e7a3b=""
                    >
                      <button
                        role="button"
                        type="button"
                        arial-label="app_general_accessibility_back_button 4"
                        className="tgtg-pagination__trigger"
                        data-v-f08e7a3b=""
                      >
                        3
                      </button>
                    </li>
                    {/*]*/}
                  </ul>
                  <button
                    aria-label="Go to next results page"
                    role="button"
                    className="tgtg-pagination__trigger tgtg-pagination__trigger--forward"
                    data-v-f08e7a3b=""
                  >
                    <span
                      className="tgtg-pagination__trigger-accessible-txt"
                      data-v-f08e7a3b=""
                    >
                      app_general_accessibility_forward_button
                    </span>
                  </button>
                </div>
                {/*]*/}
              </div>
              <div className="tgtg-slider-promo__cta" data-v-815364f1="">
                {/*[*/}
                {/*[*/}
                <button className="tgtg-button" data-v-815364f1="">
                  Download the app
                </button>
                {/*]*/}
                {/*]*/}
              </div>
            </div>
          </div>
        </div>
        <div
          className="tgtg-stats-promo tgtg-wrapper"
          data-v-1cd515bb=""
          data-v-2e4b4287=""
        >
          <div
            className="tgtg-stats-promo--large tgtg-content-wrapper"
            data-v-2e4b4287=""
          >
            <div
              className="tgtg-hero-heading tgtg-hero-heading--center tgtg-hero-heading--medium tgtg-hero-heading--null tgtg-stats-promo__title"
              data-v-2e4b4287=""
              data-v-55260b66=""
            >
              <h2
                className="tgtg-hero-heading__heading-content"
                data-v-55260b66=""
              >
                {/*[*/}
                <span className="tgtg-hero-heading__plain" data-v-55260b66="">
                  DISCOVER DEALS NEAR{" "}
                </span>
                <span
                  className="tgtg-hero-heading__highlight"
                  data-v-55260b66=""
                >
                  YOU
                </span>
                {/*]*/}
              </h2>
            </div>
            <p className="tgtg-stats-promo__description" data-v-2e4b4287="">
              There's plenty of food in your area just waiting to be saved. Head
              to the Too Good To Go app to find Surprise Bags from your
              favourite shops and restaurants.
            </p>
            <div
              className="tgtg-slider tgtg-slider--1-visible-slides tgtg-stats-promo__slider"
              data-v-2e4b4287=""
              data-v-e5d97b2e=""
            >
              <div className="tgtg-slider__mask" data-v-e5d97b2e="">
                <ul
                  id="tgtg-carousel_tgtgStatsPromo"
                  aria-roledescription="slide"
                  aria-live="polite"
                  className="tgtg-slider__list tgtg-content-wrapper"
                  style={{ transform: "translateX(-0px)" }}
                  data-v-e5d97b2e=""
                >
                  {/*[*/}
                  <li
                    aria-hidden="false"
                    className="tgtg-slider__slide"
                    data-v-e5d97b2e=""
                  >
                    {/*[*/}
                    {/**/}
                    <p className="tgtg-stats-promo__figure" data-v-2e4b4287="">
                      <span
                        className="tgtg-stats-promo__figure__value"
                        data-v-2e4b4287=""
                      >
                        11,796
                      </span>
                    </p>
                    <p
                      className="tgtg-stats-promo__extra-info"
                      data-v-2e4b4287=""
                    >
                      Bakeries
                    </p>
                    {/*]*/}
                  </li>
                  <li
                    aria-hidden="true"
                    className="tgtg-slider__slide"
                    data-v-e5d97b2e=""
                  >
                    {/*[*/}
                    {/**/}
                    <p className="tgtg-stats-promo__figure" data-v-2e4b4287="">
                      <span
                        className="tgtg-stats-promo__figure__value"
                        data-v-2e4b4287=""
                      >
                        6,027
                      </span>
                    </p>
                    <p
                      className="tgtg-stats-promo__extra-info"
                      data-v-2e4b4287=""
                    >
                      Restaurants
                    </p>
                    {/*]*/}
                  </li>
                  <li
                    aria-hidden="true"
                    className="tgtg-slider__slide"
                    data-v-e5d97b2e=""
                  >
                    {/*[*/}
                    {/**/}
                    <p className="tgtg-stats-promo__figure" data-v-2e4b4287="">
                      <span
                        className="tgtg-stats-promo__figure__value"
                        data-v-2e4b4287=""
                      >
                        9,909
                      </span>
                    </p>
                    <p
                      className="tgtg-stats-promo__extra-info"
                      data-v-2e4b4287=""
                    >
                      Supermarkets
                    </p>
                    {/*]*/}
                  </li>
                  {/*]*/}
                </ul>
              </div>
              {/*[*/}
              <div className="tgtg-slider__controls" data-v-e5d97b2e="">
                <button
                  role="button"
                  type="button"
                  aria-controls="tgtg-carousel_tgtgStatsPromo"
                  disabled=""
                  className="tgtg-slider__controls__trigger tgtg-slider__controls__trigger--back"
                  data-v-e5d97b2e=""
                >
                  <span
                    className="tgtg-slider__controls__trigger-accessible-txt"
                    data-v-e5d97b2e=""
                  >
                    app_general_accessibility_back_button
                  </span>
                </button>
                <button
                  role="button"
                  aria-controls="tgtg-carousel_tgtgStatsPromo"
                  className="tgtg-slider__controls__trigger tgtg-slider__controls__trigger--forward"
                  data-v-e5d97b2e=""
                >
                  <span
                    className="tgtg-slider__controls__trigger-accessible-txt"
                    data-v-e5d97b2e=""
                  >
                    app_general_accessibility_forward_button
                  </span>
                </button>
              </div>
              {/*]*/}
            </div>
          </div>
        </div>
        <div
          className="tgtg-faq"
          id="faq"
          data-v-1cd515bb=""
          data-v-ac8c4967=""
        >
          <div className="tgtg-wrapper" data-v-ac8c4967="">
            <div className="tgtg-content-wrapper" data-v-ac8c4967="">
              <div
                className="tgtg-hero-heading tgtg-hero-heading--center tgtg-hero-heading--medium tgtg-hero-heading--null tgtg-faq__title"
                data-v-ac8c4967=""
                data-v-55260b66=""
              >
                <h2
                  className="tgtg-hero-heading__heading-content"
                  data-v-55260b66=""
                >
                  {/*[*/}
                  <span className="tgtg-hero-heading__plain" data-v-55260b66="">
                    WHAT TO{" "}
                  </span>
                  <span
                    className="tgtg-hero-heading__highlight"
                    data-v-55260b66=""
                  >
                    KNOW
                  </span>
                  {/*]*/}
                </h2>
              </div>
              {/**/}
              <div className="tgtg-faq__wrapper" data-v-ac8c4967="">
                <div className="tgtg-faq__wrapper-body" data-v-ac8c4967="">
                  <div className="tgtg-faq__list-wrapper" data-v-ac8c4967="">
                    <ul className="tgtg-faq__list" data-v-ac8c4967="">
                      {/*[*/}
                      <li
                        className="tgtg-faq__list-item"
                        aria-controls="answer--0"
                        aria-selected="false"
                        data-v-ac8c4967=""
                      >
                        <div
                          className="tgtg-faq__list-item-wrapper"
                          data-v-ac8c4967=""
                        >
                          <button
                            role="button"
                            className="tgtg-faq__list-item-question"
                            data-v-ac8c4967=""
                          >
                            <span
                              className="tgtg-faq__list-item-question-copy"
                              data-v-ac8c4967=""
                            >
                              How much does it cost?
                            </span>
                          </button>
                          <div
                            id="answer--0"
                            className="tgtg-faq__list-item-answer"
                            role="region"
                            data-v-ac8c4967=""
                          >
                            <div
                              className="tgtg-faq__list-item-answer-text"
                              data-v-ac8c4967=""
                            >
                              The app is free to download, and Surprise Bags are
                              sold for great prices that will differ depending
                              on their contents.
                            </div>
                            {/**/}
                          </div>
                        </div>
                      </li>
                      <li
                        className="tgtg-faq__list-item"
                        aria-controls="answer--1"
                        aria-selected="false"
                        data-v-ac8c4967=""
                      >
                        <div
                          className="tgtg-faq__list-item-wrapper"
                          data-v-ac8c4967=""
                        >
                          <button
                            role="button"
                            className="tgtg-faq__list-item-question"
                            data-v-ac8c4967=""
                          >
                            <span
                              className="tgtg-faq__list-item-question-copy"
                              data-v-ac8c4967=""
                            >
                              Do you have a list with stores in the
                              neighborhood?
                            </span>
                          </button>
                          <div
                            id="answer--1"
                            className="tgtg-faq__list-item-answer"
                            role="region"
                            data-v-ac8c4967=""
                          >
                            <div
                              className="tgtg-faq__list-item-answer-text"
                              data-v-ac8c4967=""
                            >
                              You can find this in the app - it's free to
                              download.
                            </div>
                            {/**/}
                          </div>
                        </div>
                      </li>
                      <li
                        className="tgtg-faq__list-item"
                        aria-controls="answer--2"
                        aria-selected="false"
                        data-v-ac8c4967=""
                      >
                        <div
                          className="tgtg-faq__list-item-wrapper"
                          data-v-ac8c4967=""
                        >
                          <button
                            role="button"
                            className="tgtg-faq__list-item-question"
                            data-v-ac8c4967=""
                          >
                            <span
                              className="tgtg-faq__list-item-question-copy"
                              data-v-ac8c4967=""
                            >
                              What kind of products can I find on the app?
                            </span>
                          </button>
                          <div
                            id="answer--2"
                            className="tgtg-faq__list-item-answer"
                            role="region"
                            data-v-ac8c4967=""
                          >
                            <div
                              className="tgtg-faq__list-item-answer-text"
                              data-v-ac8c4967=""
                            >
                              We work with lots of different types of stores and
                              restaurants, so you'll find anything from
                              supermarket groceries, to hotel breakfasts, to
                              fresh pastries from bakeries. Any fresh food that
                              hasn't passed its use by date can be sold on the
                              app. Occasionally, you might receive an item that
                              has passed its best before date. This date is just
                              there to indicate quality, not safety, so the food
                              will still be perfectly safe (and delicious!) to
                              consume.
                            </div>
                            {/**/}
                          </div>
                        </div>
                      </li>
                      {/*]*/}
                    </ul>
                    <div className="tgtg-faq__cta-wrapper" data-v-ac8c4967="">
                      {/**/}
                    </div>
                  </div>
                  <div className="tgtg-faq__image-wrapper" data-v-ac8c4967="">
                    <picture data-v-ac8c4967="">
                      <source
                        srcSet="data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs="
                        media="(max-width: 768px)"
                        data-v-ac8c4967=""
                      />
                      <img
                        className="tgtg-faq__image"
                        src="https://tgtg-mkt-cms-prod.s3.eu-west-1.amazonaws.com/37874/lEfDYgwBqptvwxAe"
                        alt="A woman with a mobile phone in one hand and the Too Good To Go bag in the other hand"
                        data-v-ac8c4967=""
                      />
                    </picture>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*]*/}
        {/*]*/}
        {/*]*/}
      </main>
      <div className="tgtg-alerts" data-v-12c90a19="" data-v-696db267="">
        {/*[*/}
        {/*]*/}
      </div>
      <footer className="tgtg-footer" data-v-12c90a19="" data-v-d13c869d="">
        <div
          className="tgtg-footer__wrapper tgtg-content-wrapper"
          data-v-d13c869d=""
        >
          <ul
            className="tgtg-footer__nav__list tgtg-footer__nav__list--other"
            data-v-d13c869d=""
          >
            {/*[*/}
            <li className="tgtg-footer__nav__item" data-v-d13c869d="">
              <a
                href="/en-gb/blog"
                className="tgtg-footer__nav__link"
                data-v-d13c869d=""
              >
                Blog
              </a>
            </li>
            <li className="tgtg-footer__nav__item" data-v-d13c869d="">
              <a
                href="/en/careers"
                className="tgtg-footer__nav__link"
                data-v-d13c869d=""
              >
                Careers
              </a>
            </li>
            <li className="tgtg-footer__nav__item" data-v-d13c869d="">
              <a
                href="/en-gb/press"
                className="tgtg-footer__nav__link"
                data-v-d13c869d=""
              >
                Press
              </a>
            </li>
            <li className="tgtg-footer__nav__item" data-v-d13c869d="">
              <a
                href="/en-gb/support"
                className="tgtg-footer__nav__link"
                data-v-d13c869d=""
              >
                Support
              </a>
            </li>
            <li className="tgtg-footer__nav__item" data-v-d13c869d="">
              <a
                href="https://store.toogoodtogo.com"
                rel="noopener noreferrer"
                className="tgtg-footer__nav__link"
                data-v-d13c869d=""
              >
                MyStore
              </a>
            </li>
            {/*]*/}
          </ul>
          <ul
            className="tgtg-footer__nav__list tgtg-footer__nav__list--legal"
            data-v-d13c869d=""
          >
            {/*[*/}
            <li className="tgtg-footer__nav__item" data-v-d13c869d="">
              <a
                href="/en-gb/privacy-policy"
                className="tgtg-footer__nav__link"
                data-v-d13c869d=""
              >
                Privacy Policy
              </a>
            </li>
            <li className="tgtg-footer__nav__item" data-v-d13c869d="">
              <a
                href="/en-gb/cookie-policy"
                className="tgtg-footer__nav__link"
                data-v-d13c869d=""
              >
                Cookie Policy
              </a>
            </li>
            <li className="tgtg-footer__nav__item" data-v-d13c869d="">
              <a
                href="/en-gb/terms-and-conditions"
                className="tgtg-footer__nav__link"
                data-v-d13c869d=""
              >
                Terms &amp; Conditions
              </a>
            </li>
            <li className="tgtg-footer__nav__item" data-v-d13c869d="">
              <a
                href="/en-us/referralterms"
                className="tgtg-footer__nav__link"
                data-v-d13c869d=""
              >
                Promotion and Referral Terms
              </a>
            </li>
            <li className="tgtg-footer__nav__item" data-v-d13c869d="">
              <a
                href="/en-gb/mediation-and-complaint"
                className="tgtg-footer__nav__link"
                data-v-d13c869d=""
              >
                Mediation &amp; Complaint Report
              </a>
            </li>
            {/*]*/}
          </ul>
          <ul
            className="tgtg-app-market-logos tgtg-footer__cta"
            data-v-d13c869d=""
            data-v-f5bc2ac9=""
          >
            <li className="tgtg-app-market-logos__market" data-v-f5bc2ac9="">
              <a
                href="https://app.tgtg.to/jBEOve"
                className="tgtg-app-market-logos__market__link"
                data-v-f5bc2ac9=""
              >
                <img
                  src="../../_nuxt/ios_badge_en.a26fc5b3.svg"
                  alt="appStore"
                  className="tgtg-app-market-logos__market__logo"
                  data-v-f5bc2ac9=""
                />
              </a>
            </li>
            <li className="tgtg-app-market-logos__market" data-v-f5bc2ac9="">
              <a
                href="https://app.tgtg.to/jBEOve"
                className="tgtg-app-market-logos__market__link"
                data-v-f5bc2ac9=""
              >
                <img
                  src="../../_nuxt/google_badge_en.dd12d068.svg"
                  alt="googlePlay"
                  className="tgtg-app-market-logos__market__logo"
                  data-v-f5bc2ac9=""
                />
              </a>
            </li>
            <li className="tgtg-app-market-logos__market" data-v-f5bc2ac9="">
              <a
                href="https://apps.apple.com/GB/app/id1060683933?mt=8"
                className="tgtg-app-market-logos__market__link"
                data-v-f5bc2ac9=""
              >
                <img
                  src="../../_nuxt/huawei_badge_de.e2f87158.svg"
                  alt="appGallery"
                  className="tgtg-app-market-logos__market__logo"
                  data-v-f5bc2ac9=""
                />
              </a>
            </li>
          </ul>
          <ul className="tgtg-footer__social" data-v-d13c869d="">
            {/*[*/}
            <li className="tgtg-footer__social__item" data-v-d13c869d="">
              <a
                aria-label="Go to Too Good To Go's twitter_logo page"
                href="https://twitter.com/toogoodtogo_uk"
                target="_blank"
                className="tgtg-footer__social__link"
                data-v-d13c869d=""
              >
                <img
                  className="tgtg-icon tgtg-footer__social__logo"
                  src="../../_nuxt/twitter_logo_dark.5b1cde21.svg"
                  alt="twitter_logo_dark"
                  data-v-d13c869d=""
                />
              </a>
            </li>
            <li className="tgtg-footer__social__item" data-v-d13c869d="">
              <a
                aria-label="Go to Too Good To Go's youtube_logo page"
                href="https://www.youtube.com/channel/UC3MUjO_YyZUvn49DJXuqjog"
                target="_blank"
                className="tgtg-footer__social__link"
                data-v-d13c869d=""
              >
                <img
                  className="tgtg-icon tgtg-footer__social__logo"
                  src="../../_nuxt/youtube_logo_dark.59cf738e.svg"
                  alt="youtube_logo_dark"
                  data-v-d13c869d=""
                />
              </a>
            </li>
            <li className="tgtg-footer__social__item" data-v-d13c869d="">
              <a
                aria-label="Go to Too Good To Go's linkedin_logo page"
                href="https://www.linkedin.com/company/too-good-to-go"
                target="_blank"
                className="tgtg-footer__social__link"
                data-v-d13c869d=""
              >
                <img
                  className="tgtg-icon tgtg-footer__social__logo"
                  src="../../_nuxt/linkedin_logo_dark.9f0d0f8c.svg"
                  alt="linkedin_logo_dark"
                  data-v-d13c869d=""
                />
              </a>
            </li>
            <li className="tgtg-footer__social__item" data-v-d13c869d="">
              <a
                aria-label="Go to Too Good To Go's instagram_logo page"
                href="https://www.instagram.com/toogoodtogo.uk"
                target="_blank"
                className="tgtg-footer__social__link"
                data-v-d13c869d=""
              >
                <img
                  className="tgtg-icon tgtg-footer__social__logo"
                  src="../../_nuxt/instagram_logo_dark.703cb7f7.svg"
                  alt="instagram_logo_dark"
                  data-v-d13c869d=""
                />
              </a>
            </li>
            <li className="tgtg-footer__social__item" data-v-d13c869d="">
              <a
                aria-label="Go to Too Good To Go's facebook_logo page"
                href="https://facebook.com/toogoodtogoUK"
                target="_blank"
                className="tgtg-footer__social__link"
                data-v-d13c869d=""
              >
                <img
                  className="tgtg-icon tgtg-footer__social__logo"
                  src="../../_nuxt/facebook_logo_dark.337cf5ca.svg"
                  alt="facebook_logo_dark"
                  data-v-d13c869d=""
                />
              </a>
            </li>
            {/*]*/}
          </ul>
          <img
            className="tgtg-icon tgtg-footer__bcorp"
            src="../../_nuxt/bcorp_logo_dark.cc6747c2.svg"
            alt="bcorp_logo_dark"
            data-v-d13c869d=""
          />
          <p className="tgtg-footer__copyright" data-v-d13c869d="">
            Copyright © 2023 Too Good To Go International. All Rights Reserved.
          </p>
        </div>
      </footer>
    </div>
    {/*]*/}
    {/**/}
    {/**/}
    {/*]*/}
  </div>
</>


  );
}

export default HomePage;
